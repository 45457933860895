import { FullWidthImage } from './FullWidthImage'
import { FullWidthText } from './FullWidthText'
import { LeftAlginedImage } from './LeftAlignedImage'
import { RightAlginedImage } from './RightAlignedImage'

export const components = {
    full_width_text: FullWidthText,
    left_aligned_image: LeftAlginedImage,
    right_aligned_image: RightAlginedImage,
    full_width_image: FullWidthImage,
} 