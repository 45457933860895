import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Box,
    Flex
} from '@chakra-ui/react';

import { PrismicRichText } from '@prismicio/react';

export function LeftAlginedImage({ slice }) {
    return (
        <Box my="16">
            <Flex w="100%" h="100%" flexWrap="wrap">
                <Box w={{base: '100%', lg: '50%'}} p="4">
                    <GatsbyImage image={getImage(slice.primary.image.localFile)} alt={slice.primary.image.alt} imgStyle={{width: '100%', height: 'auto'}} />
                </Box>
                <Flex flexDirection="column"
                    h="100%"
                    alignContent="center"
                    my="auto"
                    w={{base: '100%', lg: '50%'}}
                    p="4">
                   <Box pr={{base: 'none', lg: '48'}}>
                        <PrismicRichText
                            field={slice.primary.content.richText}
                        />
                   </Box>
                </Flex>
            </Flex>
        </Box>
    )
}
