import React, { useState, useRef } from "react"
import {
  Container,
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react"

import { useForm } from "react-hook-form"

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const formRef = useRef(null);
  
  const onSubmit = data => {
    //const formData = new FormData(formRef.current);
    
    
    setProcessing(true);

    fetch('/.netlify/functions/send-contact-form', {
              method: 'POST',
              headers: {
                "Content-Type": "application/json"
            },            
                body: JSON.stringify(data)
        })
        .then((response) => {
            if (response.status === 200) {
                // console.log(JSON.stringify(data));
                setSucceeded(true);
                reset();
            } else {
                console.log('network error')
            }
        })
    
      }

  const SuccessMessage = () => (
    <Box>
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      borderRadius="3rem"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Message submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
          Thanks for your submission. We'll be in touch soon.
        </AlertDescription>
    </Alert>
  </Box>
  )


  return (
    <>
      <Container maxWidth="container.md" mb="24">
      { succeeded ? <SuccessMessage /> : (
        <>

        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <Flex w="100%" flexWrap="wrap">
                <Box w={{base: '100%', lg: '50%'}} mb={8} pr={{base: 0, lg: '2'}}>
                  <FormControl id="name">
                    <FormLabel>Your Name<Text color="olive-green.500" as="span">*</Text></FormLabel>
                    <Input type="text"
                        {...register("name", {required: true} )}
                    />
                    {errors.name && <Text color="olive-green.500">Please enter your name.</Text>}
                  </FormControl>
                </Box>
                <Box w={{base: '100%', lg: '50%'}} mb={8} pl={{base: 0, lg: '2'}}>
                <FormControl id="email">
                <FormLabel>Email address<Text color="olive-green.500" as="span">*</Text></FormLabel>
                <Input type="email" 
                   {...register("email", 
                    {
                      required: true, 
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                     }
                  }
                  )}
                   />
                   {errors.email && <Text color="olive-green.500">Please enter a valid email address.</Text>}
              </FormControl>
                </Box>
            </Flex>
          
          <Box mb={8}>
          <FormControl id="email">
                <FormLabel>Phone number</FormLabel>
                <Input type="tel" 
                   {...register("phone", 
                    {
                      pattern: {
                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
                     }
                  }
                  )}
                   />
                   {errors.phone && <Text color="#bf1650">Please enter a valid phone number.</Text>}
              </FormControl>
          </Box>
                     
        <Box>
            <FormControl id="message" mb={8}>
            <FormLabel>Message<Text color="olive-green.500" as="span">*</Text></FormLabel>
                <Textarea placeholder=""
                    {...register("message", {required: true} )}
                    />
                    {errors.message && <Text color="#bf1650">Please enter a message.</Text>}
            </FormControl>
        </Box>
          <Box mt="4" textAlign="center">
          <Button 
            disabled={!isValid || processing}
            type="submit"
            mt={4}
            isLoading={processing}
            loadingText='Submitting'
            >
              Submit
          </Button>
          </Box>
        </form>
        </>
      )
}
      </Container>
    </>
  )
}

export default ContactForm
