import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Box,
} from '@chakra-ui/react';

export function FullWidthImage({ slice }) {
    return (
        <Box>
            <GatsbyImage image={getImage(slice.primary.image.localFile)} alt={slice.primary.image.alt} imgStyle={{width: '100%', height: 'auto'}} />
        </Box>

    )
}
