import React from 'react';
import {
    Box,
    Container,
    Flex
} from '@chakra-ui/react';

import { PrismicRichText } from '@prismicio/react';

export function FullWidthText({ slice }) {
    return (
        <Container maxW="container.lg" my="8">
            <Flex w="100%" h="100%" alignItems="center">
                <Box w="100%">
                    <PrismicRichText
                        field={slice.primary.content.richText}
                    />
                </Box>
            </Flex>
        </Container>
    )
}
