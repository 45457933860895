import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { 
  Box,
  Heading,
} from '@chakra-ui/react'
import { SliceZone } from '@prismicio/react';
import { components } from '../components/Slices';
import Seo from '../components/Seo'
import ContactForm from '../components/ContactForm/ContactForm'


const ContactPage = ({ data, location }) => {
  
  const pageContent = data.prismicContactPage.data;
  
  return (
    <Layout
        pageLocation={location}
        headerImage={pageContent.header_image?.localFile}
        headerImageAlt={pageContent.header_image?.alt}
    >
      <Seo title={pageContent.seo_title.text} description={pageContent.meta_description.text} />
      <Box my="16">
          <Heading as="h2" variant="h2" textAlign="center">
            {pageContent.page_title}
          </Heading>
      </Box>
        
       <Box>
           <SliceZone
            slices={pageContent.body}
            components={components}
            />
       </Box>

       <ContactForm />

    </Layout>
  )
}

export const query = graphql`
{
  prismicContactPage {
    data {
      page_title
      header_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      body {
        ... on PrismicContactPageDataBodyFullWidthText {
          id
          slice_type
          primary {
            content {
              richText
            }
          }
        }
        ... on PrismicContactPageDataBodyFullWidthImage {
          id
          slice_type
          primary {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
                }
              }
              alt
            }
          }
        }
      }
      seo_title {
        text
      }
      meta_description {
        text
      }
    }
    uid
  }
}
`

export default ContactPage;